import { Description } from '@mui/icons-material'
import { title } from 'process'

export const ItaContent = {
  title: 'Politica sui Cookie',
  uodateTime: 'Aggiornata: 19 aprile 2024',
  description:
    'iMile Italy S.r.l. (denominata “iMile Italy”) e le sue affiliate (collettivamente, “iMile”, “noi”, “ci” e “nostro”) rispettano la tua privacy. Con la presente sviluppiamo questa Politica sui Cookie (denominata “questa Politica”) per aiutarti a comprendere come utilizziamo i cookie su questo sito web (denominato “il nostro Sito Web”) e per dimostrare il nostro ferreo impegno per la privacy delle tue informazioni personali. Ti preghiamo di prenderti un momento per leggere attentamente questa Politica e di contattarci se hai domande:',
  email: 'DPO@imile.me',
  text:
    "Ulteriori informazioni sulla protezione dei dati possono essere trovate nella nostra Informativa sulla Privacy all'indirizzo:",
  url: 'https://www.imile.com/privacy-policy',
  content1: {
    title: 'Cosa sono i cookie?',
    desc:
      "I cookie e tecnologie simili sono documenti di testo molto piccoli o frammenti di codice che spesso contengono un codice di identificazione univoco. Quando visiti un sito web o utilizzi un'applicazione mobile, un computer chiede al tuo computer o dispositivo mobile il permesso di salvare questo file sul tuo computer o dispositivo mobile e di accedere alle informazioni. Le informazioni raccolte tramite cookie e tecnologie simili possono includere la data e l'ora della visita e il modo in cui utilizzi un determinato sito web o un'applicazione mobile.",
  },
  content2: {
    title: 'Perché utilizziamo i cookie',
    desc:
      'I cookie possono ricordare le tue credenziali di accesso, in modo che non sia necessario inserire tali credenziali ogni volta che accedi a un servizio. I cookie garantiscono che possiamo vedere come viene utilizzato il nostro sito web e come possiamo migliorarlo. Inoltre, in base alle tue preferenze, i nostri stessi cookie possono essere utilizzati per presentarti annunci mirati che corrispondono ai tuoi interessi personali.',
  },
  content3: {
    title: 'Quanti tipi di cookie utilizziamo?',
    desc: 'iMile Italy utilizza i seguenti tipi di cookie su questo sito web:',
    opts: [
      {
        title: 'Cookies per parte terza',
        childs: [
          {
            title: 'Cookie di prima parte',
            text:
              'I cookie di prima parte sono memorizzati su un sito web (dominio) che un utente ha visitato direttamente. Gli editori utilizzano questi cookie per raccogliere dati analitici e ottimizzare le funzionalità del sito web, come ad esempio ricordare le preferenze linguistiche. I cookie di prima parte sono attivi per impostazione predefinita e non scompariranno presto. Ciò è dovuto al fatto che sono essenziali per eseguire funzionalità chiave del sito web.',
          },
          {
            title: 'Cookie di terza parte',
            text:
              "I cookie di terza parte sono impostati da un'organizzazione diversa dal proprietario del sito web che stai visitando. Ad esempio, il sito web potrebbe utilizzare un provider di analisi di terza parte che imposta il proprio cookie per fornire questo servizio. Il sito web che stai visitando potrebbe anche contenere contenuti incorporati, ad esempio da YouTube o Facebook, e questi siti web potrebbero impostare i propri cookie.",
          },
        ],
      },
      {
        title: 'Cookie per durata',
        childs: [
          {
            title: 'Cookie di Sessione',
            text:
              "I cookie di sessione sono memorizzati per la durata di una visita al sito web e vengono automaticamente eliminati quando il browser viene chiuso. Garantiscono, ad esempio, che i file audio e video possano essere riprodotti, che le informazioni inserite dall'utente siano temporaneamente memorizzate durante il tempo di immissione e che quindi l'esperienza dell'utente sia migliorata.",
          },
          {
            title: 'Cookie Persistenti',
            text:
              "I cookie persistenti rimangono sul tuo dispositivo terminale anche dopo aver chiuso il browser. Questi cookie possono, ad esempio, memorizzare le tue preferenze utente, come le impostazioni linguistiche, e analizzare il comportamento dell'utente sul nostro sito web. Il periodo di conservazione dei cookie persistenti è determinato individualmente per ciascun cookie. Dopo la scadenza del periodo, vengono eliminati automaticamente.",
          },
        ],
      },
      {
        title: 'Cookie per categoria',
        childs: [
          {
            title: 'Cookie Essenziali',
            text:
              "Questi cookie sono essenziali per il corretto funzionamento del sito web e non possono essere disattivati senza influire sull'utilizzo del sito. Solitamente vengono impostati solo in risposta ad azioni da parte tua che comportano una richiesta di servizi, come ad esempio impostare le tue preferenze sulla privacy, effettuare l'accesso o compilare moduli. Questi cookie non memorizzano dati personali.",
            desc: 'Alcune delle azioni seguenti possono essere eseguite utilizzando questi cookie:',
            opts: [
              '-memorizzare articoli in un carrello per gli acquisti online,',
              '-salvare le preferenze sui cookie per questo sito web,',
              '-salvare le preferenze linguistiche,',
              "-accedere al nostro portale. Abbiamo bisogno di verificare se hai effettuato l'accesso.",
            ],
          },
          {
            title: 'Cookie di Performance',
            text:
              'Questi cookie sono cookie non essenziali, anche conosciuti specificamente come "cookie di misurazione" o "cookie statistici". Questi cookie ci permettono di conteggiare le visite e le fonti di traffico in modo da poter misurare e migliorare le prestazioni del nostro sito web. Ci aiutano a sapere quali pagine sono le più e le meno popolari e come i visitatori si muovono nel sito. Tutte le informazioni raccolte da questi cookie sono aggregate e quindi anonime.',
          },
          {
            title: 'Cookie Funzionali',
            text:
              'Questi cookie consentono al sito web di offrire funzionalità avanzate e personalizzazione. Possono essere impostati da noi o da fornitori terzi i cui servizi abbiamo aggiunto alle nostre pagine. Le seguenti funzionalità possono essere attivate o meno quando accetti una di queste categorie:',
            opts: [
              '-servizi di chat live,',
              '-guardare video online,',
              '-pulsanti di condivisione sui social media.',
            ],
          },
          {
            title: 'Cookie Pubblicitari / di Tracciamento',
            text:
              'Questi cookie sono impostati da partner pubblicitari esterni e vengono utilizzati per profilare e tracciare i dati su più siti web. Se accetti questi cookie, potremmo mostrare i nostri annunci su altri siti web basandoci sul tuo profilo utente e sulle tue preferenze. Questi cookie possono anche salvare dati su quanti visitatori hanno visto o cliccato sui nostri annunci al fine di ottimizzare le campagne pubblicitarie.',
          },
        ],
      },
    ],
  },
  tableTitle: 'Le informazioni sui singoli cookie possono essere trovate di seguito.',
  tableData: [
    {
      nome: 'Cookie essenziali',
    },
    {
      nome: 'EXPRESS_LANG',
      scopo: 'Per memorizzare le preferenze linguistiche',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'Prima parte',
    },
    {
      nome: 'EXPRESS_ACCESS_TOKEN',
      scopo:
        "Richiesta dell'intestazione di autenticazione dell'identità dell'utente mediante bearer",
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'Prima parte',
    },
    {
      nome: 'USER_INFO',
      scopo: "Per condividere le informazioni di accesso dell'utente su diversi nomi di dominio",
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'Prima parte',
    },
    {
      nome: 'Cookie di performance',
    },
    {
      nome: '_fbp',
      scopo: "Per tracciare e registrare l'attività e il comportamento dell'utente sul sito web",
      durata: '30 giorni',
      fornitore: 'Facebook pixel',
      tipo: 'Terza parte',
    },
    {
      nome: '_ga_JZB7SGNDNX',
      scopo: "Per tracciare e registrare l'attività e il comportamento dell'utente sul sito web",
      durata: '400 giorni',
      fornitore: 'Google tag manager',
      tipo: 'Terza parte',
    },
    {
      nome: '_ga_XM0HEHEK5N',
      scopo: "Per tracciare e registrare l'attività e il comportamento dell'utente sul sito web",
      durata: '400 giorni',
      fornitore: 'Google tag manager',
      tipo: 'Terza parte',
    },
    {
      nome: '_ga',
      scopo: "Per tracciare e registrare l'attività e il comportamento dell'utente sul sito web",
      durata: '60 giorni',
      fornitore: 'Google tag manager',
      tipo: 'Terza parte',
    },
    {
      nome: '_ga_TKCPHWF8FX',
      scopo: "Per tracciare e registrare l'attività e il comportamento dell'utente sul sito web",
      durata: '400 giorni',
      fornitore: 'Google tag manager',
      tipo: 'Terza parte',
    },
    {
      nome: 'Cookie funzionali',
    },
    {
      nome: 'NID',
      scopo: 'Per riprodurre i video di YouTube incorporati sul nostro sito web',
      durata: '175 giorni',
      fornitore: 'Youtube',
      tipo: 'Terza parte',
    },
    {
      nome: 'IDE',
      scopo: 'Per riprodurre i video di YouTube incorporati sul nostro sito web',
      durata: '208 giorni',
      fornitore: 'Youtube',
      tipo: 'Terza parte',
    },
    {
      nome: 'Id',
      scopo: 'Per riprodurre i video di YouTube incorporati sul nostro sito web',
      durata: '399 giorni',
      fornitore: 'Youtube',
      tipo: 'Terza parte',
    },
    {
      nome: 'VISITOR_INFO1_LIVE',
      scopo: 'Per riprodurre i video di YouTube incorporati sul nostro sito web',
      durata: '172 giorni',
      fornitore: 'Youtube',
      tipo: 'Terza parte',
    },
    {
      nome: 'YSC',
      scopo: 'Per riprodurre i video di YouTube incorporati sul nostro sito web',
      durata: 'Sessione',
      fornitore: 'Youtube',
      tipo: 'Terza parte',
    },
    {
      nome: 'Cookie Pubblicitari / di Tracciamento',
    },
    {
      nome: 'X-AB',
      scopo:
        'Snapchat traccia il comportamento di visualizzazione delle pagine degli utenti sui siti web. Questo può essere utilizzato per analizzare e ottimizzare le campagne pubblicitarie',
      durata: '16 ore',
      fornitore: 'Snap Inc.',
      tipo: 'Terza parte',
    },
    {
      nome: 'sc_at',
      scopo:
        'Snapchat traccia il comportamento di visualizzazione delle pagine degli utenti sui siti web. Questo può essere utilizzato per analizzare e ottimizzare le campagne pubblicitarie',
      durata: '395 giorni 8 ore 18minuti',
      fornitore: 'Snap Inc.',
      tipo: 'Terza parte',
    },
    {
      nome: '_scid',
      scopo:
        'Snapchat traccia il comportamento di visualizzazione delle pagine degli utenti sui siti web. Questo può essere utilizzato per analizzare e ottimizzare le campagne pubblicitarie',
      durata: '395 giorni 8 ore 18minuti',
      fornitore: 'Snap Inc.',
      tipo: 'Terza parte',
    },
    {
      nome: '_sctr',
      scopo:
        'Snapchat traccia il comportamento di visualizzazione delle pagine degli utenti sui siti web. Questo può essere utilizzato per analizzare e ottimizzare le campagne pubblicitarie',
      durata: '395 giorni 8 ore 18minuti',
      fornitore: 'Snap Inc.',
      tipo: 'Terza parte',
    },
    {
      nome: '_scid_r',
      scopo:
        'Snapchat traccia il comportamento di visualizzazione delle pagine degli utenti sui siti web. Questo può essere utilizzato per analizzare e ottimizzare le campagne pubblicitarie',
      durata: '395 giorni 8 ore 18minuti',
      fornitore: 'Snap Inc.',
      tipo: 'Terza parte',
    },
  ],
  content4: {
    title: 'Gestione dei cookie',
    opts: [
      "Se necessario, chiederemo il tuo consenso all'uso di determinati cookie descritti in questa Politica quando accedi al sito web. In tal caso, comparirà un banner quando visiti per la prima volta il sito web, informandoti dell'uso di tali cookie. Se rifiuti i cookie, non imposteremo alcun cookie sul tuo dispositivo diversi da quelli per i quali non è richiesto il consenso o quelli strettamente necessari (compreso un cookie per indicare che non accetti l'impostazione dei cookie quando visiti il sito web).",
      "Puoi scegliere di non accettare tutti i cookie tranne quelli necessari. Puoi anche disattivare i cookie modificando le impostazioni del browser del tuo sito web in modo che i cookie vengano rifiutati. Come fare dipende dal browser che stai utilizzando. La maggior parte dei browser ti consente di modificare le impostazioni dei cookie, che di solito si trovano nel menu 'Opzioni' o 'Preferenze' del tuo browser. Utilizza l'opzione 'Aiuto' nel tuo browser per ulteriori informazioni. Se blocchi i cookie, potrebbe essere impossibile godere di tutte le funzionalità tecniche offerte dal nostro sito web, e potrebbe influire negativamente sulla tua esperienza utente.",
      'per ulteriori informazioni. Se blocchi i cookie, potrebbe essere impossibile godere di tutte le funzionalità tecniche offerte dal nostro sito web, e potrebbe influire negativamente sulla tua esperienza utente.',
    ],
    text:
      "La modifica o il ritiro del consenso per l'installazione dei cookie possono essere effettuati utilizzando il seguente link:",
    openDrawer: 'Impostazioni dei Cookie.',
  },
  content5: {
    title: 'Contatti',
    text:
      'Se hai domande, preoccupazioni o reclami relativi a questa Politica sui Cookie, non esitare a contattarci. Puoi raggiungerci al seguente indirizzo:',
    email: 'DPO@imile.me',
  },
  columns: [
    {
      key: 'nome',
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      key: 'scopo',
      title: 'Scopo',
      dataIndex: 'scopo',
    },
    {
      key: 'durata',
      title: 'Durata',
      dataIndex: 'durata',
    },
    {
      key: 'fornitore',
      title: 'Fornitore',
      dataIndex: 'fornitore',
    },
    {
      key: 'tipo',
      title: 'Tipo',
      dataIndex: 'tipo',
    },
  ],
}

export const AusContent = {
  title: 'Cookie Policy',
  uodateTime: 'Updated: April 30, 2024',
  description:
    'iMile Delivery&Logistic Pty Ltd(refer to as "iMile Australia") and its affiliates (collectively, "iMile", "we", "us", and "our") respect your privacy. We hereby develop this Cookie Policy (refer to as “this Policy”) to help you understand how we use cookies on this website (refer to as "our Website"), and to demonstrate our firm commitment to the privacy of your personal information. Please take a moment to read this Policy carefully and contact us  if you have any questions.',
  text: 'More information on data protection you can find in our Privacy Policy under',
  url: 'https://www.imile.com/privacy-policy',
  content1: {
    title: 'What are cookies?',
    desc:
      'Cookies and similar technologies are very small text documents or pieces of code that often contain a unique identification code. When you visit a website or use a mobile application, a computer asks your computer or mobile device for permission to save this file on your computer or mobile device and gain access to information. Information collected through cookies and similar technologies may include the date and time of the visit and how you use a particular website or mobile application.',
  },
  content2: {
    title: 'Why do we use cookies',
    desc:
      'Cookies can remember your sign-in credentials, so you don’t have to enter those credentials each time you log on to a service. The cookies ensure that we can see how our website is used and how we can improve it. Furthermore, depending on your preferences our own cookies may be used to present you with targeted advertisements that match your personal interests.',
  },
  content3: {
    title: 'What type of cookies do we use?',
    desc: 'iMile Australia uses the following types of cookies on this website:',
    opts: [
      {
        title: 'Cookies by Party',
        childs: [
          {
            title: 'First party cookies',
            text:
              'First-party cookies are stored on a website (domain) a user has visited directly. Publishers use these cookies to collect analytical data and optimize website functionality, like remembering the language preferences. First-party cookies are active by default and will not go away any time soon. This is because they are essential to perform key website features.',
          },
          {
            title: 'Third party cookies',
            text:
              'Third party cookies are set by an organization other than the owner of the website you are visiting. For example, the website may use a third-party analytics provider that sets its own cookie to perform this service. The website you are visiting may also contain content embedded from, for example, YouTube or Facebook, and these websites may set their own cookies.',
          },
        ],
      },
      {
        title: 'Cookies by Duration',
        childs: [
          {
            title: 'Session cookies',
            text:
              'Session cookies are stored for the duration of a website visit and then automatically deleted when the browser is closed. They ensure, for example, that video and audio files can be played, your user input is temporarily stored during the input time and thus the user experience is improved.',
          },
          {
            title: 'Persistent cookies',
            text:
              'Persistent cookies remain on your terminal device even after you close the browser. These cookies can, for example, store your user preferences, such as language settings, and analyze user behavior on our website. The storage period of persistent cookies is determined individually per cookie. After expiry of the period, they are automatically deleted. ',
          },
        ],
      },
      {
        title: 'Cookies by Category',
        childs: [
          {
            title: 'Essential cookies',
            text:
              'These cookies are essential for the website to function properly and cannot be switched off without affecting the use of the site. They are usually only set in response to actions you take that amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. These cookies do not store any personal data.',
            desc: 'Some of the following actions can be performed by using these cookies:',
            opts: [
              '- Store articles in a shopping cart for online purchases',
              '- Save your cookie preferences for this website',
              '- Saving language preferences',
              '- Log in to our portal. We need to check whether you are logged in.',
            ],
          },
          {
            title: 'Performance cookies',
            text:
              'These cookies are non-essential cookies, which are also specifically known as “measurement cookies” or “statistical cookies”. These cookies allow us to count visits and traffic sources so that we can measure and improve the performance of our website. They help us to know which pages are the most and least popular and how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous.',
          },
          {
            title: 'Functional cookies',
            text:
              'These cookies enable the website to offer enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages.',
            desc:
              'The following functionalities may or may not be activated when you accept this category:',
            opts: [
              '- Live chat services',
              '- Watch online videos',
              '- Social media sharing buttons',
            ],
          },
          {
            title: 'Advertising / tracking cookies',
            text:
              'These cookies are set by external advertising partners and are used for profiling and tracking data across multiple websites. If you accept these cookies, we may show our advertisements on other websites based on your user profile and preferences. These cookies may also save data about how many visitors have seen or clicked on our advertisements in order to optimize advertising campaigns.',
          },
        ],
      },
    ],
  },
  tableTitle: 'Information on the individual cookies can be found below.',
  tableData: [
    {
      nome: 'Essential Cookies',
    },
    {
      nome: 'EXPRESS_LANG',
      scopo: 'To store language preferences',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'EXPRESS_ACCESS_TOKEN',
      scopo: 'Request header bearer user identity authentication',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'USER_INFO',
      scopo: 'To share user login information in different domain names',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'Performance cookies',
    },
    {
      nome: '_fbp',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '30 days',
      fornitore: 'Facebook pixel',
      tipo: 'Third party',
    },
    {
      nome: '_ga_JZB7SGNDNX',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_XM0HEHEK5N',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '60 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_TKCPHWF8FX',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: 'Functional Cookies',
    },
    {
      nome: 'NID',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '175 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'IDE',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '208 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Id',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '399 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'VISITOR_INFO1_LIVE',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '172 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'YSC',
      scopo: 'To play YouTube videos embedded on our website',
      durata: 'Session',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Advertising / tracking cookies',
    },
    {
      nome: 'X-AB',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '16 hours',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: 'sc_at',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_sctr',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid_r',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
  ],
  content4: {
    title: 'Managing cookies',
    opts: [
      'If necessary, we will ask for your consent to the use of certain cookies described in this Policy when you access the website. If so, a banner will appear when you first visit the website informing you of the use of such cookies. If you reject the cookies, we will not set any cookies on your device other than those for which consent is not required or those that are strictly necessary (including a cookie to indicate that you do not accept the cookies to be set when you visit the website).',
      "You can choose to opt out of all but the necessary cookies. You can also deactivate cookies by changing the settings of your website browser so that cookies are rejected. How to do this depends on the browser you are using. Most browsers allow you to change your cookie settings, which can usually be found in the 'Options' or 'Preferences' menu of your browser. Use the 'Help' option in your browser for more information. If you block the cookies, it is possible that you will not be able to enjoy all the technical features our website has to offer, and it may negatively affect your user experience.",
    ],
    text: 'The modification or withdrawal of consent for the placement of cookies can be made in',
    openDrawer: 'Consent Settings.',
  },
  content5: {
    title: 'Contact',
    text:
      'If you have any questions, concerns or complaints in relation to this Cookie Policy, please feel free to contact us. You can reach us under',
    email: 'DPO@imile.me',
  },
  columns: [
    {
      key: 'nome',
      title: 'Name',
      dataIndex: 'nome',
    },
    {
      key: 'scopo',
      title: 'Purpose',
      dataIndex: 'scopo',
    },
    {
      key: 'durata',
      title: 'Duration',
      dataIndex: 'durata',
    },
    {
      key: 'fornitore',
      title: 'Provider',
      dataIndex: 'fornitore',
    },
    {
      key: 'tipo',
      title: 'Type',
      dataIndex: 'tipo',
    },
  ],
}

export const enContent = {
  title: 'Cookie Policy',
  uodateTime: 'Updated: April 19, 2024',
  description:
    'iMile Italy S.r.l. (refer to as "iMile Italy") and its affiliates (collectively, "iMile", "we", "us", and "our") respect your privacy. We hereby develop this Cookie Policy (refer to as “this Policy”) to help you understand how we use cookies on this website (refer to as "our Website"), and to demonstrate our firm commitment to the privacy of your personal information. Please take a moment to read this Policy carefully and contact us  if you have any questions.',
  text: 'More information on data protection you can find in our Privacy Policy under',
  url: 'https://www.imile.com/privacy-policy',
  content1: {
    title: 'What are cookies?',
    desc:
      'Cookies and similar technologies are very small text documents or pieces of code that often contain a unique identification code. When you visit a website or use a mobile application, a computer asks your computer or mobile device for permission to save this file on your computer or mobile device and gain access to information. Information collected through cookies and similar technologies may include the date and time of the visit and how you use a particular website or mobile application.',
  },
  content2: {
    title: 'Why do we use cookies',
    desc:
      'Cookies can remember your sign-in credentials, so you don’t have to enter those credentials each time you log on to a service. The cookies ensure that we can see how our website is used and how we can improve it. Furthermore, depending on your preferences our own cookies may be used to present you with targeted advertisements that match your personal interests.',
  },
  content3: {
    title: 'What type of cookies do we use?',
    desc: 'iMile Italy uses the following types of cookies on this website:',
    opts: [
      {
        title: 'Cookies by Party',
        childs: [
          {
            title: 'First party cookies',
            text:
              'First-party cookies are stored on a website (domain) a user has visited directly. Publishers use these cookies to collect analytical data and optimize website functionality, like remembering the language preferences. First-party cookies are active by default and will not go away any time soon. This is because they are essential to perform key website features.',
          },
          {
            title: 'Third party cookies',
            text:
              'Third party cookies are set by an organization other than the owner of the website you are visiting. For example, the website may use a third-party analytics provider that sets its own cookie to perform this service. The website you are visiting may also contain content embedded from, for example, YouTube or Facebook, and these websites may set their own cookies.',
          },
        ],
      },
      {
        title: 'Cookies by Duration',
        childs: [
          {
            title: 'Session cookies',
            text:
              'Session cookies are stored for the duration of a website visit and then automatically deleted when the browser is closed. They ensure, for example, that video and audio files can be played, your user input is temporarily stored during the input time and thus the user experience is improved.',
          },
          {
            title: 'Persistent cookies',
            text:
              'Persistent cookies remain on your terminal device even after you close the browser. These cookies can, for example, store your user preferences, such as language settings, and analyze user behavior on our website. The storage period of persistent cookies is determined individually per cookie. After expiry of the period, they are automatically deleted. ',
          },
        ],
      },
      {
        title: 'Cookies by Category',
        childs: [
          {
            title: 'Essential cookies',
            text:
              'These cookies are essential for the website to function properly and cannot be switched off without affecting the use of the site. They are usually only set in response to actions you take that amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. These cookies do not store any personal data.',
            desc: 'Some of the following actions can be performed by using these cookies:',
            opts: [
              '- Store articles in a shopping cart for online purchases',
              '- Save your cookie preferences for this website',
              '- Saving language preferences',
              '- Log in to our portal. We need to check whether you are logged in.',
            ],
          },
          {
            title: 'Performance cookies',
            text:
              'These cookies are non-essential cookies, which are also specifically known as “measurement cookies” or “statistical cookies”. These cookies allow us to count visits and traffic sources so that we can measure and improve the performance of our website. They help us to know which pages are the most and least popular and how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous.',
          },
          {
            title: 'Functional cookies',
            text:
              'These cookies enable the website to offer enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. The following functionalities may or may not be activated when you accept this category:',
            opts: [
              '- Live chat services',
              '- Watch online videos',
              '- Social media sharing buttons',
            ],
          },
          {
            title: 'Advertising / tracking cookies',
            text:
              'These cookies are set by external advertising partners and are used for profiling and tracking data across multiple websites. If you accept these cookies, we may show our advertisements on other websites based on your user profile and preferences. These cookies may also save data about how many visitors have seen or clicked on our advertisements in order to optimize advertising campaigns.',
          },
        ],
      },
    ],
  },
  tableTitle: 'Information on the individual cookies can be found below.',
  tableData: [
    {
      nome: 'Essential Cookies',
    },
    {
      nome: 'EXPRESS_LANG',
      scopo: 'To store language preferences',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'EXPRESS_ACCESS_TOKEN',
      scopo: 'Request header bearer user identity authentication',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'USER_INFO',
      scopo: 'To share user login information in different domain names',
      durata: 'Sessione',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'Performance cookies',
    },
    {
      nome: '_fbp',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '30 days',
      fornitore: 'Facebook pixel',
      tipo: 'Third party',
    },
    {
      nome: '_ga_JZB7SGNDNX',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_XM0HEHEK5N',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '60 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_TKCPHWF8FX',
      scopo: 'To track and record user activity and behaviour on the website',
      durata: '400 days',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: 'Functional Cookies',
    },
    {
      nome: 'NID',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '175 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'IDE',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '208 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Id',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '399 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'VISITOR_INFO1_LIVE',
      scopo: 'To play YouTube videos embedded on our website',
      durata: '172 days',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'YSC',
      scopo: 'To play YouTube videos embedded on our website',
      durata: 'Session',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Advertising / tracking cookies',
    },
    {
      nome: 'X-AB',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '16 hours',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: 'sc_at',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_sctr',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid_r',
      scopo:
        "Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze and optimize ad campaigns",
      durata: '395d8h18m',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
  ],
  content4: {
    title: 'Managing cookies',
    opts: [
      'If necessary, we will ask for your consent to the use of certain cookies described in this Policy when you access the website. If so, a banner will appear when you first visit the website informing you of the use of such cookies. If you reject the cookies, we will not set any cookies on your device other than those for which consent is not required or those that are strictly necessary (including a cookie to indicate that you do not accept the cookies to be set when you visit the website).',
      "You can choose to opt out of all but the necessary cookies. You can also deactivate cookies by changing the settings of your website browser so that cookies are rejected. How to do this depends on the browser you are using. Most browsers allow you to change your cookie settings, which can usually be found in the 'Options' or 'Preferences' menu of your browser. Use the 'Help' option in your browser for more information. If you block the cookies, it is possible that you will not be able to enjoy all the technical features our website has to offer, and it may negatively affect your user experience.",
    ],
    text: 'The modification or withdrawal of consent for the placement of cookies can be made in',
    openDrawer: 'Consent Settings.',
  },
  content5: {
    title: 'Contact',
    text:
      'If you have any questions, concerns or complaints in relation to this Cookie Policy, please feel free to contact us. You can reach us under',
    email: 'DPO@imile.me',
  },
  columns: [
    {
      key: 'nome',
      title: 'Name',
      dataIndex: 'nome',
    },
    {
      key: 'scopo',
      title: 'Purpose',
      dataIndex: 'scopo',
    },
    {
      key: 'durata',
      title: 'Duration',
      dataIndex: 'durata',
    },
    {
      key: 'fornitore',
      title: 'Provider',
      dataIndex: 'fornitore',
    },
    {
      key: 'tipo',
      title: 'Type',
      dataIndex: 'tipo',
    },
  ],
}

export const plContent = {
  title: 'Polityka Plików Cookie',
  uodateTime: 'Zaktualizowano: 8 lipca 2024	',
  description:
    'Imile logistics and transportation sp. z o.o. (zwana dalej: "iMile Polska") i podmioty należące do grupy kapitałowej iMile szanują Państwa prywatność. Niniejsza Polityka Plików Cookie (dalej zwana "Polityką") została opracowana, aby pomóc Państwu zrozumieć, w jaki sposób używamy plików cookie na naszej stronie internetowej (dalej zwaną "Stroną") oraz aby pokazać nasze zdecydowane zaangażowanie w ochronę Państwa danych osobowych. Prosimy o chwilę na dokładne przeczytanie tej Polityki. W przypadku jakichkolwiek pytań prosimy o',
  email: 'DPO@imile.me',
  text:
    'Więcej informacji na temat ochrony danych można znaleźć w naszej Polityce Prywatności pod adresem',
  url: 'https://www.imile.com/pl/privacy-policy',
  content1: {
    title: 'Czym są pliki cookie?',
    desc:
      'Pliki cookie (tzw. ciasteczka) to bardzo małe pliki tekstowe lub fragmenty kodu, których zawartość może zawierać unikalny kod identyfikacyjny. Kiedy odwiedzają Państwo stronę internetową lub korzystają z aplikacji mobilnej, komputer prosi o zgodę na zapisanie tego pliku na Państwa komputerze lub urządzeniu mobilnym oraz na dostęp do informacji. Informacje zbierane za pomocą plików cookie mogą obejmować datę i godzinę wizyty oraz sposób korzystania ze Strony.',
  },
  content2: {
    title: 'Dlaczego używamy plików cookie?',
    desc:
      'Pliki cookie mogą zapamiętać Państwa dane logowania, dzięki czemu nie muszą Państwo wprowadzać tych danych za każdym razem, gdy logują się do usługi. Pliki cookie umożliwiają nam również zobaczenie, jak nasza Strona jest używana i jak możemy ją ulepszyć. Ponadto, w zależności od Państwa preferencji, nasze własne pliki cookie mogą być używane do prezentowania Państwu ukierunkowanych reklam, które odpowiadają Państwa osobistym zainteresowaniom.',
  },
  content3: {
    title: 'Z jakich plików cookie korzystamy?',
    desc: 'iMile Polska używa następujących plików cookie na Stronie:',
    opts: [
      {
        title: 'Pliki cookie z uwagi na stronę',
        childs: [
          {
            title: 'Cookie typu „first party”',
            text:
              'Pliki cookie typu „first party”, czyli ciasteczka własne lub tzw. ciasteczka wewnętrzne są umieszczane w przeglądarce użytkownika bezpośrednio przez stronę internetową, którą odwiedził. Używane są do zbierania danych analitycznych i optymalizacji funkcjonalności stron internetowych, na przykład do zapamiętywania preferencji językowych. Ciasteczka własne są aktywne domyślnie i nie zostają usuwane automatycznie. Jest to spowodowane tym, że są one niezbędne do realizacji kluczowych funkcji Strony.',
          },
          {
            title: 'Cookie typu „third party”',
            text:
              'Cookie typu „third party” są umieszczane w przeglądarce użytkownika nie przez stronę, którą użytkownik odwiedził, a przez organizację inną niż właściciel odwiedzanej strony internetowej. Na przykład, strona internetowa może korzystać z zewnętrznego dostawcy usług analitycznych, który ustawia własny plik cookie, aby świadczyć tę usługę. Odwiedzana przez Państwa strona może również zawierać treści osadzone, na przykład z YouTube lub Facebooka, a te strony mogą ustawiać własne pliki cookie.',
          },
        ],
      },
      {
        title: 'Pliki cookie z uwagi na czas trwania',
        childs: [
          {
            title: 'Sesyjne pliki cookie',
            text:
              'Pliki cookie sesyjne są przechowywane na czas trwania wizyty na Stronie i są automatycznie usuwane po zamknięciu przeglądarki. Zapewniają one na przykład, że pliki wideo i audio mogą być odtwarzane, a Państwa dane są tymczasowo przechowywane podczas ich wprowadzania, co poprawia doświadczenie użytkownika.',
          },
          {
            title: 'Trwałe pliki cookie',
            text:
              'Trwałe pliki cookie pozostają na Państwa urządzeniu końcowym nawet po zamknięciu przeglądarki. Te pliki cookie mogą na przykład przechowywać Państwa preferencje użytkownika, takie jak ustawienia językowe i analizować zachowanie użytkownika na naszej Stronie internetowej. Okres przechowywania trwałych plików cookie jest ustalany indywidualnie dla każdego pliku cookie. Po upływie tego okresu są one automatycznie usuwane. ',
          },
        ],
      },
      {
        title: 'Pliki cookie z uwagi na kategorię',
        childs: [
          {
            title: 'Niezbędne pliki cookie',
            text:
              'Te pliki cookie są niezbędne do prawidłowego funkcjonowania strony internetowej i nie mogą być wyłączone bez wpływu na korzystanie ze strony. Zazwyczaj są one ustawiane tylko w odpowiedzi na działania podejmowane przez Państwa, które stanowią prośbę o usługi, takie jak ustawienie preferencji prywatności, logowanie się lub wypełnianie formularzy. Te pliki cookie nie przechowują żadnych danych osobowych. ',
            desc: 'Niektóre z poniższych działań mogą być wykonywane za pomocą tych plików cookie:',
            opts: [
              '- przechowywanie produktów w koszyku zakupowym podczas zakupów online,',
              '- zapisywanie preferencji dotyczących plików cookie dla tej strony internetowej,',
              '- zapisywanie preferencji językowych,',
              '- logowanie się do naszego portalu. Musimy sprawdzić, czy są Państwo zalogowani.',
            ],
          },
          {
            title: 'Wydajnościowe pliki cookie',
            text:
              'Te pliki cookie nie są niezbędnymi plikami cookie, znane są również jako "pliki cookie pomiarowe" lub "pliki cookie statystyczne". Pozwalają nam one liczyć wizyty i źródła ruchu, abyśmy mogli mierzyć i poprawiać wydajność naszej strony internetowej. Pomagają nam one dowiedzieć się, które strony są najbardziej i najmniej popularne oraz jak użytkownicy poruszają się po Stronie. Wszystkie informacje zbierane przez te pliki cookie są agregowane i dlatego anonimowe.',
          },
          {
            title: 'Funkcjonalne cookie',
            text:
              'Te ciasteczka umożliwiają stronie internetowej oferowanie ulepszonej funkcjonalności i personalizacji. Mogą być ustawiane przez nas lub przez dostawców zewnętrznych, których usługi dodaliśmy do naszych stron. Następujące funkcje mogą być, ale nie muszą, aktywowane po zaakceptowaniu tej kategorii:',
            opts: [
              '- usługi czatu na żywo,',
              '- oglądanie filmów online,',
              '- przyciski udostępniania w mediach społecznościowych.',
            ],
          },
          {
            title: 'Reklamowe/śledzące cookie ',
            text:
              'Te ciasteczka są ustawiane przez zewnętrznych partnerów reklamowych i są wykorzystywane do tworzenia profilu użytkownika oraz śledzenia danych na wielu stronach internetowych. Jeśli zaakceptują Państwo te ciasteczka, możemy wyświetlać nasze reklamy na innych stronach internetowych na podstawie Państwa profilu użytkownika i preferencji. Te ciasteczka mogą również zapisywać dane na temat tego, ile osób widziało lub kliknęło nasze reklamy, aby zoptymalizować kampanie reklamowe.',
          },
        ],
      },
    ],
  },
  tableTitle: 'Informacje na temat poszczególnych ciasteczek znajdą Państwo poniżej.',
  tableData: [
    {
      nome: 'Niezbędne cookie',
    },
    {
      nome: 'EXPRESS_LANG',
      scopo: 'By zapisać preferencje językowe',
      durata: 'Sesja',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'EXPRESS_ACCESS_TOKEN',
      scopo: 'Żądanie uwierzytelnienia tożsamości użytkownika',
      durata: 'Sesja',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'USER_INFO',
      scopo: 'Aby udostępnić informacje o logowaniu użytkownika w różnych nazwach domen',
      durata: 'Sesja',
      fornitore: 'iMile',
      tipo: 'First party',
    },
    {
      nome: 'Wydajnościowe pliki cookie',
    },
    {
      nome: '_fbp',
      scopo:
        'Śledzenie i rejestrowanie aktywności oraz zachowań użytkownika na stronie internetowej',
      durata: '30 dni',
      fornitore: 'Facebook pixel',
      tipo: 'Third party',
    },
    {
      nome: '_ga_JZB7SGNDNX',
      scopo:
        'Śledzenie i rejestrowanie aktywności oraz zachowań użytkownika na stronie internetowej',
      durata: '400 dni',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_XM0HEHEK5N',
      scopo:
        'Śledzenie i rejestrowanie aktywności oraz zachowań użytkownika na stronie internetowej',
      durata: '400 dni',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga',
      scopo:
        'Śledzenie i rejestrowanie aktywności oraz zachowań użytkownika na stronie internetowej',
      durata: '60 godzin',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: '_ga_TKCPHWF8FX',
      scopo:
        'Śledzenie i rejestrowanie aktywności oraz zachowań użytkownika na stronie internetowej',
      durata: '400 dni',
      fornitore: 'Google tag manager',
      tipo: 'Third party',
    },
    {
      nome: 'Cookie funkcjonalne',
    },
    {
      nome: 'NID',
      scopo: 'Aby odtwarzać osadzone na naszej stronie filmy YouTube',
      durata: '175 dni',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'IDE',
      scopo: 'Aby odtwarzać osadzone na naszej stronie filmy YouTube',
      durata: '208 dni',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Id',
      scopo: 'Aby odtwarzać osadzone na naszej stronie filmy YouTube',
      durata: '399 dni',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'VISITOR_INFO1_LIVE',
      scopo: 'Aby odtwarzać osadzone na naszej stronie filmy YouTube',
      durata: '172 dni',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'YSC',
      scopo: 'Aby odtwarzać osadzone na naszej stronie filmy YouTube',
      durata: 'Sesja',
      fornitore: 'Youtube',
      tipo: 'Third party',
    },
    {
      nome: 'Reklamowe/śledzące cookie',
    },
    {
      nome: 'X-AB',
      scopo:
        'Snapchat śledzi zachowanie użytkowników podczas przeglądania stron internetowych. Można to wykorzystać do analizy i optymalizacji kampanii reklamowych.',
      durata: '16 godzin',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: 'sc_at',
      scopo:
        'Snapchat śledzi zachowanie użytkowników podczas przeglądania stron internetowych. Można to wykorzystać do analizy i optymalizacji kampanii reklamowych.',
      durata: '395 dni 8godzin 18minut',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid',
      scopo:
        'Snapchat śledzi zachowanie użytkowników podczas przeglądania stron internetowych. Można to wykorzystać do analizy i optymalizacji kampanii reklamowych.',
      durata: '395 dni 8godzin 18minut',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_sctr',
      scopo:
        'Snapchat śledzi zachowanie użytkowników podczas przeglądania stron internetowych. Można to wykorzystać do analizy i optymalizacji kampanii reklamowych.',
      durata: '395 dni 8godzin 18minut',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
    {
      nome: '_scid_r',
      scopo:
        'Snapchat śledzi zachowanie użytkowników podczas przeglądania stron internetowych. Można to wykorzystać do analizy i optymalizacji kampanii reklamowych.',
      durata: '395 dni 8godzin 18minut',
      fornitore: 'Snap Inc.',
      tipo: 'Third party',
    },
  ],
  content4: {
    title: 'Zarządzanie ciasteczkami',
    opts: [
      'W razie potrzeby poprosimy o Państwa zgodę na wykorzystanie określonych ciasteczek opisanych w tej Polityce, gdy będą Państwo korzystać z witryny. W takim przypadku pojawi się baner informujący o wykorzystaniu takich ciasteczek, gdy pierwszy raz odwiedzą Państwo witrynę. Jeśli odrzucą Państwo ciasteczka, nie będziemy ustawiać żadnych ciasteczek na Państwa urządzeniu, poza tymi, na które zgoda nie jest wymagana lub te, które są ścisłe niezbędne (w tym ciasteczko wskazujące, że nie akceptują Państwo ustawienia ciasteczek podczas odwiedzania witryny).',
      'Mogą Państwo zdecydować się na wyłączenie wszystkich, oprócz niezbędnych ciasteczek. Mogą Państwo także dezaktywować ciasteczka, zmieniając ustawienia przeglądarki internetowej, aby odrzucała ciasteczka. Sposób postępowania zależy od przeglądarki, której Państwo używają. Większość przeglądarek umożliwia zmianę ustawień ciasteczek, które zazwyczaj można znaleźć w menu "Opcje" lub "Preferencje" Państwa przeglądarki. Należy skorzystać z opcji "Pomoc" w swojej przeglądarce, aby uzyskać więcej informacji. Jeśli zablokują Państwo ciasteczka, istnieje możliwość, że nie będą Państwo mogli korzystać ze wszystkich funkcji technicznych, jakie oferuje nasza witryna, co może negatywnie wpłynąć na Państwa doświadczenie użytkownika.',
    ],
    text:
      'Modyfikacja lub wycofanie zgody na umieszczenie ciasteczek można dokonać za pomocą następującego linku: Ustawienia ciasteczek.',
    openDrawer: 'Ustawienia ciasteczek.',
  },
  content5: {
    title: 'Kontakt',
    text:
      'Jeśli mają Państwo jakieś pytania, uwagi albo skargi dotyczące Polityki Plików Cookie, prosimy o kontakt. Mogą się Państwo z nami skontaktować za pomocą:',
    email: 'DPO@imile.me',
  },
  columns: [
    {
      key: 'nome',
      title: 'Nazwa',
      dataIndex: 'nome',
    },
    {
      key: 'scopo',
      title: 'Cel',
      dataIndex: 'scopo',
    },
    {
      key: 'durata',
      title: 'Czas trwania',
      dataIndex: 'durata',
    },
    {
      key: 'fornitore',
      title: 'Dostawca',
      dataIndex: 'fornitore',
    },
    {
      key: 'tipo',
      title: 'Rodzaj',
      dataIndex: 'tipo',
    },
  ],
}

export const EspContent = {
  title: 'Política de Cookies',
  uodateTime: 'Actualizado: 22 de octubre de 2024',
  text: 'iMile Spain S.r.l. (en adelante, "iMile Spain") y sus afiliados (colectivamente, "iMile", "nosotros" y "nuestro") respetan tu privacidad. Desarrollamos esta Política de Cookies (en adelante, "esta Política") para ayudarte a entender cómo utilizamos cookies en este sitio web (en adelante, "nuestro Sitio Web") y para demostrar nuestro firme compromiso con la privacidad de tu información personal. Por favor, tómate un momento para leer esta Política cuidadosamente y contáctanos si tienes alguna pregunta. Más información sobre la protección de datos puedes encontrar en nuestra Política de Privacidad en',
  url: 'https://www.imile.com/es-MX/privacy-policy',
  content1: {
    title: '¿Qué son las cookies?',
    desc:
      'Las cookies y tecnologías similares son documentos de texto muy pequeños o fragmentos de código que a menudo contienen un código de identificación único. Cuando visitas un sitio web o utilizas una aplicación móvil, una computadora solicita a tu computadora o dispositivo móvil permiso para guardar este archivo en tu dispositivo y acceder a información. La información recopilada a través de cookies y tecnologías similares puede incluir la fecha y hora de la visita y cómo utilizas un sitio web o aplicación móvil en particular.',
  },
  content2: {
    title: '¿Por qué usamos cookies?',
    desc:
      'Las cookies pueden recordar tus credenciales de inicio de sesión, por lo que no tienes que ingresarlas cada vez que inicias sesión en un servicio. Las cookies aseguran que podamos ver cómo se utiliza nuestro sitio web y cómo podemos mejorarlo. Además, dependiendo de tus preferencias, nuestras propias cookies pueden usarse para presentarte anuncios dirigidos que coincidan con tus intereses personales.',
  },
  content3: {
    title: '¿Qué tipo de cookies utilizamos?',
    desc: 'iMile España utiliza los siguientes tipos de cookies en este sitio web:',
    opts: [
      {
        title: 'Cookies por Parte',
        childs: [
          {
            title: '•	Cookies de primera parte',
            text:
              'Las cookies de primera parte se almacenan en un sitio web (dominio) que un usuario ha visitado directamente. Los editores utilizan estas cookies para recopilar datos analíticos y optimizar la funcionalidad del sitio web, como recordar las preferencias de idioma. Las cookies de primera parte están activas por defecto y no desaparecerán pronto, ya que son esenciales para realizar funciones clave del sitio web.',
          },
          {
            title: '•	Cookies de terceros',
            text:
              'Las cookies de terceros son establecidas por una organización distinta del propietario del sitio web que estás visitando. Por ejemplo, el sitio web puede utilizar un proveedor de análisis de terceros que establece su propia cookie para realizar este servicio. El sitio web que visitas también puede contener contenido incrustado de, por ejemplo, YouTube o Facebook, y estos sitios pueden establecer sus propias cookies.',
          },
        ],
      },
      {
        title: 'Cookies por Duración',
        childs: [
          {
            title: '•	Cookies de sesión',
            text:
              'Las cookies de sesión se almacenan durante la duración de una visita al sitio web y se eliminan automáticamente cuando se cierra el navegador. Aseguran, por ejemplo, que se puedan reproducir archivos de video y audio, y que tu entrada de usuario se almacene temporalmente durante el tiempo de entrada, mejorando así la experiencia del usuario.',
          },
          {
            title: '•	Cookies persistentes',
            text:
              'Las cookies persistentes permanecen en tu dispositivo incluso después de cerrar el navegador. Estas cookies pueden, por ejemplo, almacenar tus preferencias de usuario, como configuraciones de idioma, y analizar el comportamiento del usuario en nuestro sitio web. El período de almacenamiento de las cookies persistentes se determina individualmente por cookie. Después de la expiración del período, se eliminan automáticamente.',
          },
        ],
      },
      {
        title: 'Cookies por Categoría',
        childs: [
          {
            title: '•	Cookies esenciales',
            text:
              'Estas cookies son esenciales para que el sitio web funcione correctamente y no se pueden desactivar sin afectar el uso del sitio. Generalmente, solo se establecen en respuesta a acciones que realizas que constituyen una solicitud de servicios, como configurar tus preferencias de privacidad, iniciar sesión o completar formularios.',
            desc: 'Estas cookies no almacenan ningún dato personal. Algunas de las siguientes acciones pueden realizarse utilizando estas cookies:',
            opts: [
              'o	Almacenar artículos en un carrito de compras para compras en línea.',
              'o	Guardar tus preferencias de cookies para este sitio web.',
              'o	Guardar preferencias de idioma.',
              'o	Iniciar sesión en nuestro portal; necesitamos verificar si estás conectado.',
            ],
          },
          {
            title: '•	Cookies de rendimiento',
            text:
              'Estas cookies son no esenciales, también conocidas como "cookies de medición" o "cookies estadísticas". Estas cookies nos permiten contar visitas y fuentes de tráfico para que podamos medir y mejorar el rendimiento de nuestro sitio web. Nos ayudan a saber qué páginas son las más y menos populares y cómo los visitantes se mueven por el sitio. Toda la información que recopilan estas cookies es agregada y, por lo tanto, anónima.',
          },
          {
            title: '•	Cookies funcionales',
            text:
              'Estas cookies permiten al sitio web ofrecer funcionalidad mejorada y personalización. Pueden ser establecidas por nosotros o por proveedores de terceros cuyos servicios hemos añadido a nuestras páginas. Las siguientes funcionalidades pueden activarse o no al aceptar esta categoría:',
            opts: [
              'o	Servicios de chat en vivo.',
              'o	Ver videos en línea.',
              'o	Botones para compartir en redes sociales.',
            ],
          },
          {
            title: '•	Cookies de publicidad / seguimiento',
            text:
              'Estas cookies son establecidas por socios publicitarios externos y se utilizan para perfilar y rastrear datos a través de múltiples sitios web. Si aceptas estas cookies, podemos mostrarte nuestros anuncios en otros sitios web en función de tu perfil de usuario y preferencias. Estas cookies también pueden guardar datos sobre cuántos visitantes han visto o hecho clic en nuestros anuncios para optimizar campañas publicitarias.',
          },
        ],
      },
    ],
  },
}