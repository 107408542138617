import React, { useMemo, useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Table } from 'antd'
import { graphql } from 'gatsby'
import { Box } from '@mui/material'
import { Header, Footer } from '@/components'
import {
  ItaContent,
  AusContent,
  enContent,
  plContent,
  EspContent,
} from '@/components/CookiePolicy/config'
import { PrivacyNoticeDetailDrawer } from '@/components/PrivacyNoticeDetailDrawer'
import { getUrlParam, transCountry } from '@/components/PrivacyPolicy/config'

const CookiePolicy = (props) => {
  const [country, setCountry] = useState<string | undefined>('')
  const [lang, setLang] = useState<string | undefined>('')
  const footerLocale = JSON.parse(props.data.footerLocale.data)

  const { detailDrawerOpen, showDetailDrawer, detailDrawerContent } = PrivacyNoticeDetailDrawer({
    pageLocale: footerLocale,
  })

  const showContent: any = useMemo(() => {
    let t = enContent
    if (country === 'Italy' && lang === 'it_IT') {
      t = ItaContent
    }
    if (country === 'Australia') {
      t = AusContent
    }
    if (country === 'Poland') {
      t = plContent
    }
    if (country === 'Spain') {
      t = EspContent
    }
    if (country === 'New Zealand') {
      t = {
        ...t,
        uodateTime: 'Updated: [8/14/2024]',
        description:
          'iMile Logistics Services Limited (refer to as "iMile New Zealand") and its affiliates (collectively, "iMile", "we", "us", and "our") respect your privacy. We hereby develop this Cookie Policy (refer to as “this Policy”) to help you understand how we use cookies on this website (refer to as "our Website"), and to demonstrate our firm commitment to the privacy of your personal information. Please take a moment to read this Policy carefully and contact us if you have any questions.',
      }
    }
    return t
  }, [country, lang])

  const handleOpenUrl = (url: string) => {
    window.open(url)
  }

  useEffect(() => {
    const urlCountry =
      (getUrlParam('country') && transCountry[getUrlParam('country')]) || Cookies.get('COUNTRY')
    const urlLang = getUrlParam('language') || Cookies.get('EXPRESS_LANG')
    setCountry(urlCountry)
    setLang(urlLang)
  }, [])

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box
        sx={{
          pt: '90px',
          padding: '90px 24px 60px',
          h1: {
            textAlign: 'center',
            p: '30px',
          },
          div: {
            m: '20px 0',
          },
          '.pl_20': {
            paddingLeft: '20px',
            m: '0',
          },
          '.m0': {
            m: '0',
          },
          '.m5': {
            margin: '5px 0',
          },
          '.mb_12': {
            marginBottom: '12px',
          },
          '.span_6': {
            padding: '0 6px',
          },
          '.ml': {
            marginLeft: '20px',
          },
          '.ant-table-tbody': {
            'tr:nth-of-type(1),tr:nth-of-type(5),tr:nth-of-type(11)': {
              fontWeight: 'bold',
            },
          },
        }}
      >
        <h1>{showContent?.title}</h1>
        <div className='m0'>{showContent?.uodateTime}</div>
        <div>
          {showContent?.description}
          <a className='span_6' href={`mailto:${showContent?.email}`}>
            {showContent?.email}
          </a>
        </div>
        <div>
          {showContent?.text}
          <a onClick={() => handleOpenUrl(showContent?.url)} className='span_6'>
            {showContent?.url}.
          </a>
        </div>
        <div>
          <h2>{showContent?.content1?.title}</h2>
          <div>{showContent?.content1?.desc}</div>
        </div>
        <div>
          <h2>{showContent?.content2?.title}</h2>
          <div>{showContent?.content2?.desc}</div>
        </div>
        <div>
          <h2>{showContent?.content3?.title}</h2>
          <div>{showContent?.content3?.desc}</div>
          {showContent?.content3?.opts?.map((item) => (
            <>
              <h3 className='mb_12'>{item?.title}</h3>
              {item?.childs?.map((it) => (
                <div className='ml'>
                  <h4>{it?.title}</h4>
                  <div className='m5'>{it?.text}</div>
                  <div className='m5'>{it?.desc}</div>
                  {it?.opts?.map((i) => (
                    <div className='m5 pl_20'>{i}</div>
                  ))}
                </div>
              ))}
            </>
          ))}
        </div>
        <div>
          <div>{showContent?.tableTitle}</div>
          {showContent?.tableData?.length && (
            <Table
              columns={showContent?.columns}
              dataSource={showContent?.tableData}
              pagination={false}
            />
          )}
        </div>
        <div>
          <h2>{showContent?.content4?.title}</h2>
          {showContent?.content4?.opts?.map((item) => (
            <div>{item}</div>
          ))}
          <div>
            {showContent?.content4?.text}
            <a className='span_6' onClick={showDetailDrawer}>
              {showContent?.content4?.openDrawer}
            </a>
          </div>
        </div>
        <div>
          <h2>{showContent?.content5?.title}</h2>
          <div>
            {showContent?.content5?.text}
            <a className='span_6' href={`mailto:${showContent?.content5?.email}`}>
              {showContent?.content5?.email}
            </a>
          </div>
        </div>
        {detailDrawerOpen && detailDrawerContent}
      </Box>
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default CookiePolicy

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["CookiePolicy"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
